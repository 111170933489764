import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import { Typography, Container, Box } from '@material-ui/core';
import { ProductPageBySlugQuery } from '../../types/graphql-types';
import CardImage from '../components/image/card-image';
import RichContent from '../components/text/rich-content';

export interface ProductPageProps {
  data: ProductPageBySlugQuery;
}

const ProductPage = ({ data }: ProductPageProps): JSX.Element => {
  const { contentfulProduct } = data;
  const image = contentfulProduct?.heroImage?.gatsbyImageData?.images?.fallback?.src;

  return (
    <>
      <SEO title={contentfulProduct?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulProduct?.title}
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            {contentfulProduct?.description?.description}
          </Typography>
          <Typography variant="h6" align="left" color="textSecondary" component="p">
            {contentfulProduct?.publishDate}
          </Typography>
          <CardImage image={image} title="Image title" />
          <RichContent
            dangerouslySetInnerHTML={{
              __html: contentfulProduct?.body?.childMarkdownRemark?.html || '',
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query ProductPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulProduct(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        gatsbyImageData(width: 1232, quality: 90)
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
